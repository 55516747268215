<template>
  <h1>404</h1>
  <h2>Page Not Found</h2>
</template>

<script>
export default {};
</script>

<style></style>
